<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Binance integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <!-- end card -->

        <div class="row justify-content-evenly">

          <!-- Base Example -->
          <div class="accordion" id="default-accordion-example">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Step 1. Getting Started on Binance Exchange
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    The exchange may make its own visual changes.
                    Read all the tips on the exchange carefully!
                  </div>
                  <p>The first step is to create an account on <a href="https://www.binance.com/en/activity/referral-entry/CPA?ref=CPA_00BKJ0VYT8" target="_blank">Binance</a>. This usually takes between 1 to 3 days.</p>
                  <p>After registration, you will need to complete verification to confirm your identity.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Step 2. Top up your account on the exchange
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    To trade on the stock exchange you will need <strong>USDT</strong>.
                  </div>
                  <p>
                    In order for our system to make transactions, you must have USDT in your account.
                    <br>
                    You have 2 options:
                  </p>
                  <ol>
                    <li>Transfer assets (USDT) from another exchange or wallet by going to this <a href="https://www.binance.com/en/my/wallet/account/main/deposit/crypto" target="_blank">link</a>;</li>
                    <li>Buy the desired asset (USDT) for fiat money by clicking on this <a href="https://www.binance.com/en/crypto/buy/" target="_blank">link</a>.</li>
                  </ol>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Follow the instructions on Binance to complete the steps!
                  </div>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Please note that your assets must be stored in the account with which the specific bot of our system works.
                    For a spot bot this is a spot account, and for a futures bot this is a futures account.
                  </div>
                  <p>If everything was done correctly, you will see your assets by clicking on this <a href="https://www.binance.com/en/my/wallet/account/overview">link</a>.</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                  Step 3. Create an API key
                </button>
              </h2>
              <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-warning" role="alert">
                    <i class="las la-info-circle"></i>
                    We strongly recommend you to enable two-factor authentication.
                  </div>
                  <div class="alert alert-warning" role="alert">
                    <i class="las la-info-circle"></i>
                    We strongly recommend you to use sub-account just not to mix your personal assets with trading bot assets.
                  </div>
                  <p>To create sub-account follow this <a href="https://www.binance.com/en/my/sub-account/account-management" target="_blank">link</a>.</p>

                  <img src="/Instruction/Binance/create-sub-account.png" class="img-fluid border-dotted" />
                  <br><br>
                  <p>To create API Key follow this <a href="https://www.binance.com/en/my/sub-account/api-management" target="_blank">link</a>. </p>
                  <img src="/Instruction/Binance/create-api-key-main.png" class="img-fluid border-dotted"/>
                  <br><br>
                  <p>Choose "System Generated" option.</p>

                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    For integration you need to mark: <strong>Enable Reading</strong>, <strong>Enable Spot & Margin Trading</strong> and fill up<strong>Restrict access to trusted IPs only (Recommended)</strong> permissions
                  </div>
                  <br>

                  <div class="alert alert-warning alert-dismissible alert-label-icon label-arrow fade show col-md-6" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    For security, make sure you have added our server's IP address <strong>52.28.48.36</strong>. Field: <strong>Restrict access to trusted IPs only (Recommended)</strong>
                  </div>

                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                  Step 4. Integration with our system
                </button>
              </h2>
              <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <p>Follow the <a href="/api-keys" target="_blank">link</a> in order to add an API key to our system.</p>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    To fill in all required fields, use the data from the previous step.
                  </div>
                  <img src="/Instruction/Binance/j2p-add-api-key.png" class="img-fluid border-dotted col-md-6"/>
                  <br><br>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="heading6">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                  Step 5. Create a bot in our system
                </button>
              </h2>
              <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#default-accordion-example">
                <div class="accordion-body">
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    Carefully read the information on the bot creation page!
                  </div>
                  <p>To create a trading bot, go to the specified <a href="/bots" target="_blank">link</a> </p>

                  <img src="/Instruction/Binance/j2p-create-bot.png" class="img-fluid border-dotted col-md-6" />
                  <br>
                  <br>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    The amount of your deposit affects the number of active trades and the amount of trades.
                  </div>
                  <div class="alert alert-info alert-dismissible alert-label-icon label-arrow fade show col-md-7" role="alert">
                    <i class="las la-info-circle label-icon"></i>
                    We recommend having as many active trades as possible.
                  </div>
                  <p>
                    Ensure that <a href="/bots" target="_blank">your bots</a> are activated.
                  </p>
                  <img src="/Instruction/Binance/bots-list.png" class="img-fluid border-dotted col-md-7" />
                </div>
              </div>
            </div>
          </div><!--end col-->.
        </div><!--end row-->
      </div><!--end row-->
    </div><!--end row-->
  </Layout>
</template>
